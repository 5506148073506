import { GameModelFromFeed, ShortGameModelFromFeed } from '@/root/app/[locale]/games/[slug]';
import { Endpoints, fetchData, gameOptions, strapiOptions } from './data-fetcher';
import { ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { notFound } from 'next/navigation';
import { logger } from '@/libs/utils/logger';
import { capitalizeFirstLetter } from '../utils/utils';

type FetchGameFeed = ({ feedId, lang }: { feedId: number; lang: string }) => Promise<GameModelFromFeed[]>;

export type TFeedFetchParams = {
	feedId: number | null;
	lang: string;
};
export const fetchGameFeed: FetchGameFeed = async ({ feedId, lang }) => {
	if (!feedId) {
		logger.warn('[fetchGameFeed] Feed Id is null');
		return notFound();
	}
	const url = Endpoints.getGameFeed(feedId, lang);
	return fetchData(url, gameOptions);
};

type FetchShortGameFeed = (feedId: number, lang: string) => Promise<ShortGameModelFromFeed[]>;

type FetchGameByCategory = (feedId: number, lang: string, categorySlug: string) => Promise<ShortGameModelFromFeed[]>;

type FetchGameBySlug = (feedId: number, lang: string, slug: string) => Promise<ShortGameModelFromFeed[]>;

type FetchGamePageConfig = (id: number) => Promise<ConfigGamePage>;

export const fetchShortGameFeed: FetchShortGameFeed = async (feedId, lang) => {
	const data = await fetchGameFeed({ feedId, lang });
	return data.map((game: GameModelFromFeed) => ({
		slug: game.slug,
		name: game.name,
		meta: {
			name: game.meta?.name,
			alias: game.meta?.alias,
			details: game.meta?.details,
			description: game.meta?.description,
			categories: game.meta?.categories,
			tags: game.meta?.tags,
			badges: game.meta?.badges,
			thumbs: {
				graphic_56x56: game.meta?.thumbs?.graphic_56x56,
				graphic_288x192: game.meta?.thumbs?.graphic_288x192,
				graphic_184x400: game.meta?.thumbs?.graphic_184x400,
				graphic_592x400: game.meta?.thumbs?.graphic_592x400,
				icon_graphic_112x112: game.meta?.thumbs?.graphic_592x400,
			},
		},
	}));
};

export const fetchGamesByCategorySlug: FetchGameByCategory = async (feedId, lang, categorySlug) => {
	const feed = await fetchGameFeed({ feedId, lang });
	const categoryGames = feed.filter((game) => {
		return game.meta.categories.includes(capitalizeFirstLetter(categorySlug));
	});

	return categoryGames;
};

export const fetchGameBySlug: FetchGameBySlug = async (feedId, lang, slug) => {
	const feed = await fetchGameFeed({ feedId, lang });
	const game = feed.find((game) => game.slug === slug);
	return game ? [game] : [];
};

export const fetchGamePageConfig: FetchGamePageConfig = async (id: number) => {
	const url = Endpoints.getGamePageConfig(id);
	return fetchData(url, strapiOptions);
};
